import React from "react";
import LayoutWithOutPadding from "../components/LayoutWithOutPadding";
import { Col } from "antd";

class BlogPage extends React.Component {
  render() {
    return (
      <LayoutWithOutPadding>
        <Col style={{ minHeight: "100vh" }}>
          <iframe
            id="blog-iframe"
            src={"https://blog.unschool.in"}
            width="100%"
            height="1550"
          />
        </Col>
      </LayoutWithOutPadding>
    );
  }
}

export default BlogPage;
